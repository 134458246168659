body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'LM', 'WM',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: black;
  color: white;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "WM";
  src: local("WM"), url(./fonts/Watermelon_Days.woff) format("woff");
}

@font-face {
  font-family: "LM";
  src: local("LM"), url(./fonts/Lemon_Days.woff) format("woff");
}

.p-accordion-header {
  font-family: 'WM';
  font-size: 26px;
  color: black;
}

.p-timeline-event {
  min-height: 200px!important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  color: black!important;
}

.p-timeline .p-timeline-event-marker {
  background-color: #FD373A!important;
  border: 2px solid #FD373A!important;
}

.p-timeline .p-timeline-event-connector {
  background-color: #FD373A!important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border: 1px solid black!important;
}

.p-accordion .p-accordion-content {
  border: 1px solid #000000!important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
 box-shadow: 0 0 0 0.1rem black!important;
}

.react-reveal {
  width: 100%!important;
}